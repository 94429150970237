/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
export const baseSocketsMixin = {
  sockets: {
    update_space: function (newSpace) {
      this.$store.commit('account/UPDATE_SPACES_LIST', JSON.parse(newSpace));
    },
    update_credits: function (credits) {
      this.$store.commit('account/UPDATE_CREDITS', JSON.parse(credits));
    },
    update_user: function (user) {
      this.$store.dispatch('user/SET_USER', JSON.parse(user));
    },
    update_credentials: function (credential) {
      credential = JSON.parse(credential);
      const account = this.$store.getters['account/account'];
      if (!account?.users) return;
      const currentUser = this.$store.getters['user/user'];
      const isAdmin = this.$store.getters['account/isAdmin'];
      let shouldUpdateList = false;
      if (isAdmin) {
        shouldUpdateList = true;
      } else {
        const availableCredentials = account?.users.find(u => u._id === currentUser._id).available_credentials;
        shouldUpdateList = availableCredentials?.some(c => c._id === credential._id);
      }
      if (shouldUpdateList) {
        this.$store.commit('credentials/UPDATE_CREDENTIALS_LIST', credential);
      }
    },
    update_credentials_integrations: function (integrationsList) {
      this.$store.commit('integrations/SET_INTEGRATIONS_LIST', JSON.parse(integrationsList));
    },
    set_proxy: function (setProxy) {
      this.$store.commit('credentials/SET_PROXY', JSON.parse(setProxy));
    },

    update_unread_counter: function (data) {
      this.$store.commit('credentials/UPDATE_UNREAD_COUNTER', JSON.parse(data));
    },

    authorization: function (response) {
      this.$store.commit('credentials/SET_AUTHORIZATION', JSON.parse(response));
    },
    // update_worker: function (newWorker) {
    //   this.$store.commit('workers/UPDATE_WORKERS_LIST', JSON.parse(newWorker));
    // },
    update_workflow: function (newWorkflow) {
      this.$store.commit('workflows/UPDATE_WORKFLOWS_LIST', JSON.parse(newWorkflow));
    },

    connect: function () {
      this.$socket.emit('server_create_connection', {
        token: localStorage.getItem('token'),
        space: this.space,
      });
    },
    join_succeeded (room) {
    },
    refetch_data: async function () {
      await Promise.all([
        this.$store.dispatch('credentials/SET_CREDENTIALS_LIST'),
        this.$store.dispatch('workflows/SET_WORKFLOWS_LIST'),
      ]);
    },
    refetch_workflows: async function () {
      await this.$store.dispatch('workflows/SET_WORKFLOWS_LIST');
    },
  },
};
