<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <default-modal
    :show.sync="innerShowModal"
    :show-buttons="false"
    title="Invite User"
    name="users-invite-modal"
    height="auto"
    width="480px"
    @wheel="resetInviteCodeThrottle"
  >
    <template #default>
      <validation-observer v-slot="{ passes }">
        <form
          ref="vldParent"
          class="vld-parent"
          @submit.prevent="passes(sendInvite)"
        >
          <default-input
            :value.sync="formData.email"
            class="mb-2"
            name="Invite email"
            validation-string="required|email"
            size="s"
            placeholder="Enter email..."
          />
          <div class="flex-row flex-gap-8">
            <p class="text_s color_tertiary">
              or
            </p>
            <default-button
              title="Copy Invite Code"
              color="transparent"
              form="rectangle"
              type="button"
              size="m"
              :has-paddings="false"
              @action="copyValue(account.invite_code)"
            />
            <default-button
              title-color="color_tertiary"
              icon-name="bx-refresh"
              form="square"
              size="m"
              :has-paddings="false"
              @action="resetInviteCodeThrottle"
            />
          </div>
          <default-button
            class="margin-left"
            type="submit"
            title="Send Invite"
            color="primary"
            form="rectangle"
            size="m"
          />
        </form>
      </validation-observer>
    </template>
  </default-modal>
</template>
<script>
  import defaultModal from '@/modals/defaultModal.vue';
  import DefaultInput from '@/components/base/uiKit/DefaultInput';
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  import { ValidationObserver } from 'vee-validate';
  import { mapGetters } from 'vuex';
  import { differentFunctionsMixin } from '@/mixins/differentFunctionsMixin';
  import { loaderMixin } from '@/mixins/loaderMixin';
  import { resetInviteCode, inviteUser } from '@/api/spaceMethods';
  import { throttle } from 'lodash';

  export default {
    name: 'UsersInviteModal',
    components: {
      defaultModal,
      DefaultInput,
      DefaultButton,
      ValidationObserver,
    },
    mixins: [differentFunctionsMixin, loaderMixin],
    props: {
      showModal: {
        type: Boolean,
        required: true,
      },
    },
    data () {
      return {
        innerShowModal: this.showModal,
        formData: {
          email: '',
        },
      };
    },
    computed: {
      ...mapGetters({
        account: 'account/account',
      }),

    },
    watch: {
      innerShowModal (value) {
        if (value !== this.showModal) {
          this.$emit('update:showModal', value);
        };
      },
      showModal (value) {
        if (value !== this.innerShowModal) {
          this.innerShowModal = value;
        }
      },
    },
    created () {
      this.resetInviteCodeThrottle = throttle(this.resetInviteCode, 2000, { leading: true });
    },
    methods: {
      async resetInviteCode () {
        try {
          const newSpace = await resetInviteCode(this.account._id);
          this.$store.commit('account/UPDATE_SPACES_LIST', newSpace);
          this.$noty.success('Invite code has been refreshed, copy it again');
        } catch (e) {
          this.$noty.error(e.message);
        }
      },
      async sendInvite () {
        this.loaded = false;
        try {
          await inviteUser(this.formData.email, this.account._id);
          this.$noty.success('Invitation is sent');
        } catch (e) {
          this.$noty.error(e.message);
        } finally {
          this.loaded = true;
        }
      },
    },
  };
</script>
