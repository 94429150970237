/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
const industries = [
  'Accounting',
  'Administration & Office Support',
  'Advertising, Arts & Media',
  'Banking & Financial Services',
  'Call Centre & Customer Service',
  'Community Services & Development',
  'Construction',
  'Consulting & Strategy',
  'Design & Architecture',
  'Education & Training',
  'Engineering',
  'Farming, Animals & Conservation',
  'Financial Services',
  'Government & Defence',
  'Healthcare & Medical',
  'Hospitality & Tourism',
  'Human Resources & Recruitment',
  'Information & Communication Technology',
  'Insurance',
  'Legal',
  'Manufacturing',
  'Marketing & Communications',
  'Mining, Resources & Energy',
  'Real Estate & Property',
  'Retail & Consumer Products',
  'Sales',
  'Science & Technology',
  'Sport & Recreation',
  'Trades & Services',
  'Transportation & Logistics',
];

export default industries;
