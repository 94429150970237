<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <form
    ref="vldParent"
    class="vld-parent"
    @submit.prevent="saveChanges"
  >
    <ul class="preferences-page__notifications">
      <li class="preferences-page__notifications-item mb-3 text_s color_secondary">
        <p>Type</p>
        <p class="grid-justify-self-center">
          Email
        </p>
        <p class="grid-justify-self-center">
          Browser
        </p>
      </li>
      <li
        v-for="(info, key) in notifications"
        :key="key"
        class="preferences-page__notifications-item mb-2"
      >
        <p>{{ info.title }}</p>
        <checkbox
          class="grid-justify-self-center"
          :is-active="info.email"
          @select-checkbox="select($event, key, 'email')"
        />
        <checkbox
          class="grid-justify-self-center"
          :is-active="info.browser"
          @select-checkbox="select($event, key, 'browser')"
        />
      </li>
    </ul>
    <default-button
      class="margin-left"
      type="submit"
      title="Save changes"
      color="primary"
      form="rectangle"
      size="m"
    />
  </form>
</template>
<script>
  import Checkbox from '@/components/base/Checkbox.vue';
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  import { loaderMixin } from '@/mixins/loaderMixin';
  import { changeNotifications } from '@/api/userMethods';
  import { mapGetters } from 'vuex';
  export default {
    components: {
      Checkbox,
      DefaultButton,
    },
    mixins: [loaderMixin],
    data () {
      return {
        notifications: {
          new_for_you: {
            email: false,
            browser: false,
            title: 'New for you',
          },
          new_browser: {
            email: false,
            browser: false,
            title: 'A new browser used to sign-in',
          },
          account_activity: {
            email: false,
            browser: false,
            title: 'Account Activity',
          },
          new_li_connection: {
            email: false,
            browser: false,
            title: 'New LinkedIn Connection',
          },
          new_contact_reply: {
            email: false,
            browser: false,
            title: 'New Contact Reply',
          },
          new_unread_messages: {
            email: false,
            browser: false,
            title: 'New Unread Messages',
          },
        },
      };
    },
    computed: {
      ...mapGetters({
        user: 'user/user',
      }),
    },
    created () {
      this.setNotifications();
    },
    methods: {
      select ($event, key, type) {
        this.notifications[key][type] = !$event;
      },
      async saveChanges () {
        try {
          this.loaded = false;
          const user = await changeNotifications(this.notifications);
          this.$store.dispatch('user/SET_USER', user);
          this.$noty.success('Notification settings have been successfully edited');
        } catch (e) {
          this.$noty.error(e.message);
        } finally {
          this.loaded = true;
        }
      },
      setNotifications () {
        Object.entries(this.user.notifications).forEach(([wrapperKey, wrapperValue]) => {
          Object.entries(wrapperValue).forEach(([innerKey, innerValue]) => {
            if (innerKey !== 'title') {
              this.notifications[wrapperKey][innerKey] = innerValue;
            };
          });
        });
      },
    },
  };
</script>
<style lang="scss">
.preferences-page {
  &__notifications {
    list-style: none;

    &-item {
      display: grid;
      grid-template-columns: 1.2fr 1fr 1fr;
      width: 100%;
    }
  }
}
</style>
