<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <default-modal
    :show.sync="innerShowModal"
    :show-buttons="false"
    title="User Info"
    :name="'users-info-modal' + user._id"
    height="auto"
    width="620px"
  >
    <template #default>
      <div
        ref="vldParent"
        class="flex flex-gap-16 users-info-modal__content vld-parent"
      >
        <div
          class="flex-grow-1  flex-column"
        >
          <default-list-item
            class="mb-2"
            :title="userFullName(user.first_name, user.last_name)"
            :text="user.email"
            :label-name="isAdmin ? 'Admin' : 'Member'"
            size="s"
          />
          <!--          <div>-->
          <!--            <label class="text_s">Company Name</label>-->
          <!--            <default-input-->
          <!--              :value="user.company_name"-->
          <!--              size="xs"-->
          <!--              :is-disabled="true"-->
          <!--            />-->
          <!--          </div>-->
        </div>
        <div
          class="flex-grow-1 flex-column"
        >
          <p class="text_s mb-2">
            LinkedIn accounts
          </p>
          <template
            v-if="!isAdmin"
          >
            <ul class="mb-3 users-info-modal__credentials">
              <default-list-item
                v-for="credential in credentialsList"
                :key="credential._id"
                :title="userFullName(credential.first_name, credential.last_name)"
                :text="credential.username"
                :avatar-photo-link="credential.photo_link"
                :has-checkbox="true"
                :is-active-checkbox="isSelectedCredential(credential._id)"
                :is-hovered-list="true"
                :is-activated-list="isSelectedCredential(credential._id)"
                size="xs"
                @select="selectCredential($event, credential._id)"
              />
            </ul>
            <default-button
              class="margin-left"
              title="Save changes"
              color="primary"
              form="rectangle"
              size="s"
              @action="saveChanges"
            />
          </template>
          <p
            v-else
            class="text_s"
          >
            Admins have access to all LinkedIn accounts on workspace
          </p>
        </div>
      </div>
    </template>
  </default-modal>
</template>
<script>
  import defaultModal from '@/modals/defaultModal.vue';
  import DefaultListItem from '@/components/base/uiKit/DefaultListItem';
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  import { setCredentials, getAvailableCredentials } from '@/api/userMethods';
  import { mapGetters } from 'vuex';
  import { cloneDeep } from 'lodash';
  import { loaderMixin } from '@/mixins/loaderMixin';

  export default {
    name: 'UsersInfoModal',
    components: {
      defaultModal,
      DefaultListItem,
      // DefaultInput,
      DefaultButton,
    },
    mixins: [loaderMixin],
    props: {
      showModal: {
        type: Boolean,
        required: true,
      },
      user: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        innerShowModal: this.showModal,
        selectedCredentialsIds: [],
      };
    },
    computed: {
      ...mapGetters({
        account: 'account/account',
        credentialsList: 'credentials/credentialsList',
      }),
      userFullName () {
        return (firstName, lastName) => firstName + ' ' + lastName;
      },

      isAdmin () {
        return this.account?.admins.includes(this.user._id);
      },
      isSelectedCredential () {
        return id => this.selectedCredentialsIds.includes(id);
      },
    },
    watch: {
      innerShowModal (value) {
        if (value !== this.showModal) {
          this.$emit('update:showModal', value);
        };
      },
      showModal (value) {
        if (value !== this.innerShowModal) {
          this.innerShowModal = value;
          if (this.innerShowModal) {
            this.getAvailableCredentials();
          }
        }
      },
    },

    methods: {
      async getAvailableCredentials () {
        try {
          if (!this.$refs.vldParent) { setTimeout(() => (this.loaded = false), 100); }

          const availableCredentials = await getAvailableCredentials(this.user._id);
          this.selectedCredentialsIds = availableCredentials.map(c => c._id);
        } catch (e) {
          this.$noty.error(e.message);
        } finally {
          this.loaded = true;
        }
      },

      selectCredential (remove, id) {
        this.selectedCredentialsIds = remove
          ? this.selectedCredentialsIds.filter(c => c !== id)
          : this.selectedCredentialsIds.concat(id);
      },
      async saveChanges () {
        try {
          await setCredentials(
            this.user._id,
            this.account._id,
            this.selectedCredentialsIds
          );
          this.setSpace(this.selectedCredentialsIds);
          this.innerShowModal = false;
          this.$noty.success("You've successfully updated available credentials");
        } catch (e) {
          this.$noty.error(e.message);
        }
      },
      setSpace () {
        const newCreds = this.credentialsList.filter(c => this.selectedCredentialsIds.includes(c._id));
        const newSpace = cloneDeep(this.account);
        const userToUpdate = newSpace.users.find(u => u._id !== this.user._id);
        userToUpdate.available_credentials = newCreds;
        this.$store.commit('account/UPDATE_SPACES_LIST', newSpace);
      },
    },
  };
</script>
<style lang="scss">
.users-info-modal{
  &__credentials{
    overflow: scroll;
    flex:1
}

  &__content{
    position: relative;
    overflow: hidden;
  }
}
</style>
