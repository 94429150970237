/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { getAllWorkflows } from '@/api/workflowsMethods';

const state = () => ({
  workflowsList: null,

});

const getters = {
  workflowsList: state => {
    return state.workflowsList;
  },

};

const mutations = {
  SET_WORKFLOWS_LIST (state, payload) {
    state.workflowsList = payload;
  },
  UPDATE_WORKFLOWS_LIST (state, payload) {
    const workflowsList = state.workflowsList;
    const existingIndex = state.workflowsList.findIndex(workflow => workflow._id === payload._id);
    if (existingIndex > -1) {
      workflowsList.splice(existingIndex, 1, payload);
    } else {
      workflowsList.push(payload);
    }
    state.workflowsList = workflowsList;
  },
};

const actions = {
  async SET_WORKFLOWS_LIST ({ commit, rootState }, payload) {
    const workflows = payload || rootState.credentials.selectedCredential?._id ? await getAllWorkflows(rootState.credentials.selectedCredential._id) : [];
    commit('SET_WORKFLOWS_LIST', workflows);
    return workflows;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
