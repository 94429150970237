<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div>
    <page-sidebar-new
      class="preferences-sidebars__item mb-2"
      :list="userList.items"
      item-classes="preferences-sidebars__list-item"
    >
      <template #header>
        <sidebars-header
          :header-title="userList.title"
          :list-item-title="userSidebarTitle"
          :list-item-text="user.email"
          :list-item-avatar="user.avatar"
        />
      </template>
    </page-sidebar-new>
    <page-sidebar-new
      class="preferences-sidebars__item"
      :list="workspaceList.items"
      item-classes="preferences-sidebars__list-item"
    >
      <template #header>
        <sidebars-header
          :header-title="workspaceList.title"
          :list-item-title="account.name"
          :list-item-text="account.email"
          :list-item-avatar="account.avatar"
        />
      </template>
    </page-sidebar-new>
  </div>
</template>
<script>
  import PageSidebarNew from '@/components/PageSidebarNew';
  import SidebarsHeader from '@/components/preferencesPage/sidebars/SidebarsHeader';
  import { mapGetters } from 'vuex';
  export default {
    components: {
      PageSidebarNew,
      SidebarsHeader,
    },
    props: {
      userList: {
        type: Object,
        required: true,
      },
      workspaceList: {
        type: Object,
        required: true,
      },
    },
    computed: {
      ...mapGetters({
        account: 'account/account',
        user: 'user/user',
      }),
      userSidebarTitle () {
        return this.user.first_name + ' ' + this.user.last_name;
      },
    },
  };
</script>
<style lang="scss">
.preferences-sidebars {
  &__item {
    width: 100%;
    padding: 24px 0;
    box-shadow: 0px 2px 4px rgba(10, 32, 51, 0.06), 0px 0px 8px rgba(10, 32, 51, 0.05);
  }

  &__list-item {
    padding: 8px 36px;
  }
}
</style>
