<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <portal to="body-end-2">
    <modal
      :key="name"
      :name="name"
      :width="width"
      :height="height"
      classes="component"
      :adaptive="true"
      @closed="handleClose"
    >
      <div class="modal">
        <div class="modal__header mb-3">
          <p class="modal__title text text_m">
            {{ text }}
          </p>
          <i
            class="bx bx-x modal__close"
            @click="handleClose"
          />
        </div>
        <div
          v-if="isCustom && !isAdmin"
          class="modal__buttons "
        >
          <button
            class="primary-btn"
            @click="handleClose"
          >
            Okay!
          </button>
        </div>
        <div
          v-else
          class="modal__buttons"
        >
          <button
            class="primary-btn text"
            :class="{'primary-btn_error':!primaryMode}"
            @click="confirmDelete"
          >
            {{ buttonText }}
          </button>
          <button
            class="primary-btn primary-btn_gray"
            @click="handleClose"
          >
            Cancel
          </button>
        </div>
      </div>
    </modal>
  </portal>
</template>

<script>
  // TODO rewrite using default modal
  export default {
    name: 'DeleteConfirmationModal',
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      text: {
        type: String,
        default: '',
      },
      isAdmin: {
        type: Boolean,
        default: false,
      },
      isCustom: {
        type: Boolean,
        default: false,
      },
      buttonText: {
        type: String,
        default: '',
      },
      primaryMode: {
        type: Boolean,
        default: false,
      },
      name: {
        type: String,
        default: 'deleteConfirmationModal',
      },
      width: {
        type: String,
        default: '420px',
      },
      height: {
        type: String,
        default: 'auto',
      },
      title: {
        type: String,
        default: 'Confirm delete ',
      },
    },
    watch: {
      show (show) {
        show
          ? this.$modal.show(this.name)
          : this.$modal.hide(this.name);
      },
    },
    methods: {
      handleClose () {
        this.$emit('update:show', false);
      },
      confirmDelete () {
        this.handleClose();
        this.$emit('confirm');
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
