<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="basic-avatar">
    <h2 class="text_m text_weight_accent mb-1">
      Avatar
    </h2>
    <div class="flex-row flex-gap-8">
      <default-avatar
        size="l"
        :photo-link="avatar"
      />
      <input
        id="basic-avatar-input"
        type="file"
        name="basic-avatar-input"
        class="display-none"
        accept="image/*"
        @change="change"
      >
      <label
        for="basic-avatar-input"
      >
        <default-button
          tag="div"
          form="rectangle"
          size="m"
          color="neutral"
          title="Upload new"
        />
      </label>
    </div>
  </div>
</template>
<script>
  import DefaultAvatar from '@/components/base/uiKit/DefaultAvatar';
  import DefaultButton from '@/components/base/uiKit/DefaultButton.vue';

  export default {
    name: 'BasicAvatar',
    components: {
      DefaultAvatar,
      DefaultButton,
    },
    props: {
      avatar: {
        type: String,
        default: '',
      },
    },
    methods: {
      change (e) {
        this.$emit('change', e);
      },
    },
  };
</script>
<style lang="scss">
.basic-avatar{
&__upload{
  font-weight: 400;
  font-size: 11px;
  padding: 4px 8px;
}
}
</style>
